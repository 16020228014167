@use "./Color" as *;
@use "./Font" as *;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Toastify__toast--error {
  // background-color: #e74c3c !important;
  background-color: $danger-active !important;
}

.Toastify__toast--success {
  // background-color: #07bc0c !important;
  background-color: $success !important;
}

.Toastify__toast--warning {
  // background-color: #f0ad4d !important;
  background-color: $warning !important;
}

.Toastify__toast--default {
  background-color: #4dd2f0 !important;
}

.Toastify__toast-body {
  color: $white-color;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 0px !important;
  margin: 0px !important;
}

::-webkit-scrollbar-track {
  background-color: $scrollbar-bg-color;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;
  border-radius: $smallest-border-radius;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "";
  display: none;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  content: "*";
  display: inline-block !important;
  margin-inline-start: 4px;
  font-size: 14px;
  line-height: 1;
  color: $input-required-red-color !important;
  visibility: visible;
}

.svg-arrow-rotate-90 {
  transform: rotate(90deg);
}

.svg-arrow-rotate-180 {
  transform: rotate(-90deg);
}

.Toastify__toast-theme--light {
  color: $white-color;
}
