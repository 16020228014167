@use "../../Color" as *;

.stat-card-wrapper {
  min-width: 250px;
  border-radius: $smaller-border-radius;

  .card-stat {
    font-weight: bold;

    .ant-statistic-content-suffix {
      color: rgb(190, 194, 206);
      font-size: 15px;
    }
  }
}

.stat-card-wrapper-colored {
  .ant-card-body {
    padding: 10px 16px;
    height: 75px;
    min-width: 250px;
  }
}

.card-currency-text-bold {
  font-size: 32px;
  font-weight: 900;
}
