@use "./Color" as *;

.status-wrapper {
  padding: 3px 10px;
  border-radius: $border-radius;
  color: $white-color;
  text-align: center;
  font-weight: 500;
  text-wrap: nowrap;
  width: 100px;
}

.ordersbook-logo-wrapper {
  background-color: $primary-color;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
}

.second-side-menu {
  height: fit-content;
  margin-top: 10px;
  border-radius: 20px;
  min-width: 185px;
  padding: 2px;
  margin-right: 20px;

  .ant-menu-item {
    height: 55px;
    display: flex;
    align-items: center;
  }

  .ant-menu-item-selected {
    background-color: $diff-grey !important;
  }
}

.filter-form {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.ant-form-item {
  margin-bottom: 10px;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.client-table {
  .ant-table-row {
    cursor: pointer;
  }
}

.copy-row-wrapper {
  border-radius: $bigger-small-border-radius;
  border: 1px solid $primary-color-lighter;
  height: 40px;
  padding: 0 20px;
}

.divider-small {
  margin: 0px !important;
}

.number-bg {
  display: flex;
  background-color: $primary-color-lighter;
  border-radius: 50px !important;
  color: $white-color !important;
  height: 20px !important;
  width: 20px !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
}

.note-collapse {
  .ant-collapse-header {
    padding: 10px 0px !important;
  }
}
