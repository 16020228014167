@use "../../Color" as *;

.main-menu {
  background-color: $primary-color;
}

.side-menu-item {
  display: flex !important;
  font-weight: bold;
  padding-left: 30px !important;
  color: $left-menu-text !important;
  &.ant-menu-item-selected {
    color: $white-color !important;
  }
}

.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active,
.ant-menu-light
  > .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: transparent;
}
