@use "../../Color" as *;

.my-link-primary {
  color: $primary-color-lighter !important;
}

.my-link-primary:hover {
  color: $primary-color-lighter !important;
}

.my-link-blue {
  color: $link-blue !important;
}

.my-link-blue:hover {
  color: $link-blue !important;
}

.my-link-purple {
  color: $diff-purple-strong;
}

.my-link-purple:hover {
  color: $diff-purple-strong;
}

.my-link-footer {
  color: $text-bluish-color !important;
  font-size: 16px;
}

.my-link-footer:hover {
  color: $primary-color-lighter !important;
}
