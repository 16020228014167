@forward "./Mixins";

$primary-color: #2a0c41;
$primary-color-lighter: #585dff;
$main-btn-bg-color: linear-gradient(
  190deg,
  #00e1db 0%,
  #00a7f4 40%,
  #0076ff 80%
);
$even-smaller-border-radius: 8px;
$smallest-border-radius: 10px;
$bigger-small-border-radius: 12px;
$smaller-border-radius: 16px;
$border-radius: 20px;
$bigger-border-radius: 30px;
$white-color: #fff;
$primary-color-light: #2f4ad123;
$bg: #f4f5f7;
$primary-text: #272727;
$secondary-text: #767676;
$success: #92cf5c;
$warning: #fcc44d;
$danger: #fb6b5b;
$danger-active: #fa4733;
$border-color: #ebeff6;
$order: #8247ff;
$order-bg: #ece3ff;
$pending: #ff8947;
$return: #f4c563;
$return-bg: #fdf6e8;
$payment: #9fcc6b;
$payment-bg: #f1f7e9;
$visit: #268aff;
$visit-bg: #a8d0ff;
$left-menu-text: rgba(255, 255, 255, 0.7);
$left-menu-text-hover: rgba(255, 255, 255, 0.15);
$input-border-color: #f0f0f0;
$input-text-color: #555;
$input-bg: #f4f5f7;
$gray: #a7a6b9;
$another-gray: #a7aab5;
$default-gray: #a7b4d3;
$text-blue: #2e3e5f;
$diff-grey: #eee;
$apply-color: #37444e;
$default-gray-active: #95a0bb;
$link-hover: #7d7d7d;
$link-blue: #428bca;
$text-bluish-color: #545f7e;
$btn-blue: linear-gradient(
  94.85deg,
  #b901fa -43.38%,
  #0190fa 63.99%,
  #01cce5 137.46%
);
$grayish-blue: #d6dee1;
$diff-gray-blue: #dfe0e4;
$diff-purple: #4f118d;
$diff-purple-strong: #7e54db;
$default-grey: grey;
$subtitle: #303436;
$not-confirm-color: #0dca71;
$not-confirm-color-hover: #7dc63c;
$white-gray-color: #e5eaee;
$block-color: #f26815;
$block-color-hover: #e55f0d;
$orange-color: #eeb121;
$diff-blue: #1e419c;
$wallet-icon-bg-color: rgba(146, 207, 92, 0.1);
$xml-download-btn-color: #0000ee;
$popular-color: #00c6e7;
$card-box-shadow: rgba(116, 116, 116, 0.16);
$package-btn-box-shadow: rgba(1, 145, 250, 0.35);
$input-required-red-color: rgb(245, 169, 183);
$registration-finish-text-color: slategrey;
$package-border-color: #cececf;
$company-name-color: #5b5b65;
$modal-bg-color: #f5f5f7;
$company-modal-border-bottom: #d3d3d4;
$package-btn-bg: rgba(255, 255, 255, 0.32);
$package-border-info-color: #cacfd3;
$default-black: #000;
$dropdown-export-color: #484073;
$device-label: #6c6c6c;
$dashboard-label: #575757;
$menu-border: rgba(255, 255, 255, 0.1);
$table-border: #ddd;
$layout-header-color: #f5f5f5;
$table-header-color: #434343;
$not-exported-color: #f5f9f1;
$change-password-color: #5dcff3;
$change-password-hover-color: #37c4f0;
$chart-color-blue: #5861f7;
$chart-color-orange: #ff9e34;
$chart-color-green: #99c910;
$scrollbar-color: rgba(50, 50, 50, 0.3);
$scrollbar-bg-color: rgba(50, 50, 50, 0.1);
$pricing-page-bg: #f6f6f9;
$success-text-color: #97c23f;
$diff-purple-tree: #8870e6;
$diff-purple-bg: rgba(136, 112, 230, 0.1);
$box-shadow-dark: rgba(0, 0, 0, 0.05);
$suffix-stat-color: rgb(190, 194, 206);
$google-autocomplete-gray: #d9d9d9;
$dark-bg: #1e1e1e;

:export {
  primaryColor: $primary-color;
  primaryColorLighter: $primary-color-lighter;
  mainBtnBgColor: $main-btn-bg-color;
  whiteColor: $white-color;
  primaryColorLight: $primary-color-light;
  bg: $bg;
  primaryText: $primary-text;
  secondaryText: $secondary-text;
  success: $success;
  warning: $warning;
  danger: $danger;
  dangerActive: $danger-active;
  borderColor: $border-color;
  order: $order;
  orderBg: $order-bg;
  pending: $pending;
  return: $return;
  returnBg: $return-bg;
  payment: $payment;
  paymentBg: $payment-bg;
  visit: $visit;
  visitBg: $visit-bg;
  leftMenuText: $left-menu-text;
  leftMenuTextHover: $left-menu-text-hover;
  inputBorderColor: $input-border-color;
  inputTextColor: $input-text-color;
  inputBg: $input-bg;
  gray: $gray;
  anotherGray: $another-gray;
  defaultGray: $default-gray;
  textBlue: $text-blue;
  diffGrey: $diff-grey;
  applyColor: $apply-color;
  defaultGrayActive: $default-gray-active;
  linkHover: $link-hover;
  linkBlue: $link-blue;
  textBluishColor: $text-bluish-color;
  btnBlue: $btn-blue;
  grayishBlue: $grayish-blue;
  diffGrayBlue: $diff-gray-blue;
  diffPurple: $diff-purple;
  diffPurpleStrong: $diff-purple-strong;
  defaultGrey: $default-grey;
  subtitle: $subtitle;
  notConfirmColor: $not-confirm-color;
  notConfirmColorHover: $not-confirm-color-hover;
  whiteGrayColor: $white-gray-color;
  blockColor: $block-color;
  blockColorHover: $block-color-hover;
  orangeColor: $orange-color;
  diffBlue: $diff-blue;
  WalletIconBgColor: $wallet-icon-bg-color;
  xmlDownloadBtnColor: $xml-download-btn-color;
  popularColor: $popular-color;
  cardBoxShadow: $card-box-shadow;
  packageBtnBoxShadow: $package-btn-box-shadow;
  inputRequiredRedColor: $input-required-red-color;
  registrationFinishTextColor: $registration-finish-text-color;
  packageBorderColor: $package-border-color;
  companyNameColor: $company-name-color;
  modalBgColor: $modal-bg-color;
  companyModalBorderBottom: $company-modal-border-bottom;
  packageBtnBg: $package-btn-bg;
  packageBorderInfoColor: $package-border-info-color;
  defaultBlack: $default-black;
  dropdownExportColor: $dropdown-export-color;
  deviceLabel: $device-label;
  dashboardLabel: $dashboard-label;
  menuBorder: $menu-border;
  layoutHeaderColor: $layout-header-color;
  tableHeaderColor: $table-header-color;
  notExportedColor: $not-exported-color;
  changePasswordColor: $change-password-color;
  changePasswordHoverColor: $change-password-hover-color;
  chartColorBlue: $chart-color-blue;
  chartColorOrange: $chart-color-orange;
  chartColorGreen: $chart-color-green;
  scrollbarColor: $scrollbar-color;
  scrollbarBgColor: $scrollbar-bg-color;
  pricingPageBg: $pricing-page-bg;
  successTextColor: $success-text-color;
  diffPurpleBg: $diff-purple-bg;
  boxShadowDark: $box-shadow-dark;
  suffxStatColor: $suffix-stat-color;
  googleAutocompleteGray: $google-autocomplete-gray;
  darkBg: $dark-bg;
}
