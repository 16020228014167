@use "../../Color" as *;

.main-sider {
  .ant-layout-sider-children {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
  }
  .ant-menu-item {
    height: 60px;
    display: flex;
    align-items: center;
  }
}
