@use "../../Color" as *;

.ant-table-row {
  height: 47px;
}

.ant-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 15px;
}

.ant-table-column-sorters span {
  font-weight: bold;
}

.not-exported {
  background-color: $not-exported-color;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  font-size: 13px;
}

.my-table-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
}

.my-table-footer-card {
  box-shadow: none !important;
  border-bottom-left-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
  .ant-card-body {
    padding: 0px;
  }
}

.ant-table-caption {
  display: none;
}

.ant-table-thead {
  .ant-table-cell:first-child {
    border-start-start-radius: $smaller-border-radius !important;
  }
  .ant-table-cell:first-child:hover {
    border-start-start-radius: $smaller-border-radius !important;
  }
  .ant-table-cell:last-child {
    border-start-end-radius: $smaller-border-radius !important;
  }
  .ant-table-cell:last-child:hover {
    border-start-end-radius: $smaller-border-radius !important;
  }
}

.app-table-no-footer {
  .ant-table-row:last-child {
    .ant-table-cell:last-child {
      border-bottom-right-radius: $even-smaller-border-radius;
    }
    .ant-table-cell:first-child {
      border-bottom-left-radius: $even-smaller-border-radius;
    }
  }
}

.ant-table-wrapper .ant-table-footer {
  padding: 3px 0 !important;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 5px 10px;
}

.ant-spin {
  svg {
    width: 20px;
    height: 20px;
  }
}

.ant-table-wrapper::before {
  display: none;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  @include border-solid-bottom($table-border);
}

.ant-table-wrapper .ant-table-thead > tr > th {
  @include border-solid-bottom($table-border);
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: $table-border;
  height: 4em;
}

.ant-table-placeholder {
  z-index: 0 !important;
}

.ant-table-wrapper .ant-table-title {
  padding: 0;
}

.draggable-table thead {
  height: 51px;
}

.ant-table-summary {
  tr:nth-last-child(1) {
    .ant-table-cell:first-child {
      border-bottom-left-radius: $smaller-border-radius !important;
    }
    .ant-table-cell:last-child {
      border-bottom-right-radius: $smaller-border-radius !important;
    }
  }
}

.ant-table {
  border-start-start-radius: $smaller-border-radius !important;
  border-start-end-radius: $smaller-border-radius !important;
  border-end-start-radius: $smaller-border-radius !important;
  border-end-end-radius: $smaller-border-radius !important;
}

// .ant-table-content {
//   border-radius: $smaller-border-radius !important;
// }

.ant-table-wrapper .ant-table-summary {
  background: transparent;
}

.table-no-footer {
  .ant-table {
    border-end-start-radius: 0 !important;
    border-end-end-radius: 0 !important;
  }
}
