@use "../../Color" as *;

.menu-dropdown {
  color: $white-color;
  cursor: pointer;
  margin-top: 10px;
  padding: 5px 25px;
  text-align: center;
  display: flex;
}

.menu-header-title {
  font-size: 16px;
  font-weight: bold;
}
